
$color-map: (
	light: (
		50: #e9f0fb,
		100: #c8daf4,
		200: #a3c1ed,
		300: #7ea8e5,
		400: #6395e0,
		500: #4782da,
		600: #407ad6,
		700: #376fd0,
		800: #2f65cb,
		900: #2052c2,
	),
	secondary: (
		50: #F5E9E8,
		100: #ebd4d2,
		200: #e1bfbc,
		300: #d8a9a6,
		400: #ce9490,
		500: #c47f79,
		600: #bb6963,
		700: #b1544d,
		800: #a73f37,
		900: #9e2a21,
	)
);

@each $color-theme-key, $color-theme-var in $color-map {
	@each $color-key, $color-var in $color-theme-var {
		.background--#{$color-theme-key}-#{$color-key} {
			background-color: #{$color-var};
		}
	}
}

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}

	@return $map;
}

// Dashboard
#dashboard-buttons {
	float: right;
	margin-top: -10px;
}

.rp-appbar-left {
	padding-left: 52px;
	font-size: 16px;
	font-weight: 500;
}

@media (max-width: 1280px) {
	.rp-appbar-left {
		padding-left: 22px;
	}
}


// Login
#logo-login {
	display: block;
	margin: 0 auto 1rem;
	width: 150px;
}

// Page Layouts
.rp-paper-main {
	padding: 20px;
}

.headerWithSwitch {
	line-height: 38px;
}

fieldset {
	border: 0;
	padding: 0;
}

.MuiCard-root {
	overflow: visible;
}

/*
** Sidebar
 */

.nav-section-sep {
	display: block;
	height: 0;
	width: 120px;
	margin: 5px 0 5px 68.8px;
	border-bottom: 1px solid rgba(238, 238, 238, 0.1);
}

/*
** Insurance
 */

.insurance-guide,
.document-guide {
	position: -webkit-sticky;
	position: sticky;
	top: 112px;
	z-index: 1200;
	padding: 10px 7px;
	font-size: 11px;
	line-height: 16px;
	text-align: right;

	.ins-title {
		float: left;
		font-weight: bold;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}

	.ins-cir {
		width: 16px;
		height: 16px;
		margin: 0 5px 0 15px;
	}
}

.document-guide {
	top: 175px;
}

.ins-cir {
	width: 22px;
	height: 22px;
	margin: 0 8px 0 0;
	border-radius: 50%;
	font-size: 9px;
	font-weight: 500;
	line-height: 22px;
	text-align: center;
}

.ins-noForm {
	background: #aaa;
}

.ins-expired {
	background: #dd0000;
}

.ins-expiring {
	background: #e7d23c;
}

.ins-updated {
	background: rgb(76, 175, 80);
}


/*
** Data Grid
 */

.rp-data-grid {
	border: 0;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer,
.MuiDataGrid-root .MuiDataGrid-cell {
	border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.MuiDataGrid-colCellWrapper {
	min-width: 100% !important;
}

.rp-data-grid .MuiDataGrid-row {
	cursor: pointer;
}

.rp-datagrid-head {
	margin-bottom: 16px;
}

.rp-datagrid-toolbar {
	padding: 10px 0 0;
	display: flex;
	justify-content: flex-end;
}


.MuiAccordionSummary-root {
	padding: 0px 16px;
}

.accordion-title {
	font-size: 14px;
	line-height: 38px;
	font-weight: 500;
}

.MuiAccordionDetails-root {
	padding: 16px;
}

.MuiDataGrid-toolbarContainer {
	margin-top: 10px;
}

/*
** Loads
 */

.danger {
	color: #990000;
}

/**
 * SHIPMENTS
 */

#refresh-button {
	display: inline-block;
	float: right;
	margin: -3px 5px 0 0;
}

.shipment-legend {
	position: -webkit-sticky;
	position: sticky;
	top: 112px;
	z-index: 1200;
	padding: 10px 7px;
	font-size: 11px;
	line-height: 16px;
	text-align: right;

	.ship-title {
		float: left;
		font-weight: bold;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}

	.ship-cir {
		width: 16px;
		height: 16px;
		margin: 0 5px 0 15px;
	}
}

.ship-cir {
	width: 22px;
	height: 22px;
	margin: 0 8px 0 0;
	border-radius: 50%;
	font-size: 9px;
	font-weight: 500;
	line-height: 22px;
	text-align: center;
}

.ship-na {
	background: #aaa;
}

.ship-complete {
	background: rgb(76, 175, 80);
}

.ship-warning {
	background: #e7d23c;
}

.ship-late {
	background: #dd0000;
}

.rp-cell-update {
	//color: #9e2a21;
	font-weight: 600;
}

.shipment-location {
	margin: 0 0 5px;
	padding: 0 0 0 17px;
	list-style-type: '»';

	li {
		padding-left: 10px;
	}
}

.geo-backdrop {
	z-index: 9999;
	color: #fff;
	justify-content: center;
	text-align: center;
}



#driver-tracking {
	display: flex;
	height: 36px;
	float: right;
	margin-top: -5px;

	#driver-tracking-label {
		line-height: 34px;
		font-size: 11px;
		padding: 0 20px 0 20px;
		margin-right: 5px;
		//border-right: 1px solid #dfe6ea;
		//border-right: 1px solid rgba(255,255,255,0.12);
	}

	#driver-tracking-icon {
		height: 34px;
		padding: 8px 10px 0 10px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		color: #fff;
	}

	#driver-tracking-live-data {
		display: flex;
		align-items: center;
		padding: 0 10px;
		height: 36px;
		font-size: 11px;

		div {
			padding: 0 10px;

			span {
				font-weight: bold;
			}
		}
	}

	#driver-tracking-buttons {
		padding: 3px 10px 0 0;

		button {
			margin-left: 5px;
		}
	}
}

/*
** Maps
 */

#shipment-map-wrapper {
	position: relative;
	height: 400px;
	margin-top: 20px;
}

#shipment-map-loader {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background-color: rgba(#fff, 0.4);
}

#shipment-map {
	width: 100%;
	height: 100%;
}

#infowindow-content {
	color: #000;


}

#driverwindow-content {
	#driverwindow-title {
		font-size: 18px;
		font-weight: bold;
	}

	.driverwindow-detail {
		span {
			font-weight: 600;
		}
	}
}

/*
** ShipmentChat
 */

#chat-wrapper {
	position: fixed;
	bottom: 80px;
	right: 40px;

	#chat-icon {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	#chat-container {
		position: absolute;
		right: 70px;
		bottom: 4px;
		width: 400px;

		.chat-connected {
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-left: 8px;
			transform: translateY(-1px);
		}

		.chat-day {
			text-align: center;
			padding: 20px 0;
			font-weight: 400;
		}
	}

	.chat-dispatch,
	.chat-driver {

		.chat-text-wrap {
			display: flex;

			> span {
				max-width: 75%;
				display: flex;
				flex-direction: column;
			}
		}

		.chat-text {
			position: relative;
			display: inline-block;
			width: auto;
			color: #fff;
			font-weight: 600;
			padding: 10px 12px;
			border-radius: 6px;
			margin: 2px 0;

			&:before {
				position: absolute;
				content: '';
				top: -6px;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
			}
		}

		.chat-user {
			margin-top: 2px;
			font-size: 12px;
			font-weight: 500;
			padding-bottom: 4px;
			text-transform: capitalize;
		}

		.chat-time {
			font-size: 8px;
			opacity: 0.8;

			svg {
				font-size: 9px;
				transform: translateY(1px);
			}
		}
	}

	.chat-dispatch {
		justify-content: flex-end;

		.chat-text-wrap {
			justify-content: flex-end;

			> span {
				align-items: flex-end;
			}

			.chat-time,
			.chat-user {
				padding-right: 6px;

				svg {
					margin-right: 4px;
				}
			}

			.chat-text.chat-text-send {
				background-color: map-deep-get($color-map, light, 200);

				&:before {
					right: 14px;
					border-bottom: 6px solid map-deep-get($color-map, light, 200);
				}
			}

			.chat-text.chat-text-sent {
				background-color: map-deep-get($color-map, light, 700);

				&:before {
					right: 14px;
					border-bottom: 6px solid map-deep-get($color-map, light, 700);
				}
			}
		}

	}

	.chat-driver {
		.chat-text-wrap {

			.chat-time,
			.chat-user {
				padding-left: 6px;

				svg {
					margin-left: 4px;
				}
			}

			.chat-text {
				align-self: flex-start;
			}

			.chat-text.chat-text-sent {
				background-color: #eaeaea;//map-deep-get($color-map, secondary, 800);
				color: #454545;

				&:before {
					left: 14px;
					border-bottom: 6px solid #eaeaea;
				}
			}
		}
	}
}

@debug map-deep-get($color-map, 'light', '700');

@keyframes bouncing-loader {
	to {
		opacity: 0.5;
		transform: translateY(6px);
	}
}

.bouncing-loader {
	display: flex;
	margin: 6px 16px 10px;

	svg {
		animation: bouncing-loader 0.6s infinite alternate;

		&:nth-child(2) {
			animation-delay: 0.2s;
		}

		&:nth-child(3) {
			animation-delay: 0.4s;
		}
	}
}


/*
** Contracts
 */

.rp-contract-title {
	padding: 20px 25px;
}

.rp-contract-close {
	position: absolute;
	top: 10px;
	right: 10px;
}

/*
** BOLs
 */

.rp-bol-title {
	padding: 20px 25px;
}

.rp-bol-close {
	position: absolute;
	top: 10px;
	right: 10px;
}


/*
** Invoices
 */

.MuiDropzoneArea-root {
	min-height: 110px;
}

.MuiDropzoneSnackbar-message {
	white-space: pre-wrap;
}

/*
** Account
 */

.tabs-container {
	display: flex;
	flex-grow: 1;
}

.tabs {
	flex-basis: 160px;
	min-width: 160px;
	border-right: 1px solid #ccc;
}

.tab-content {
	flex-grow: 1;
	padding: 14px 20px 0 36px;
}

.MuiTab-wrapper {
	align-items: start;
}

/*
** TRACKING PUBLIC
 */

#track-wrapper {
	min-height: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#track-inner {
	width: 100%;
	padding: 40px 20px;
	max-width: 600px;
}

#track-form {
	text-align: center;
}

#track-content {
	display: flex;
	flex-direction: column;
	padding: 20px 0 0;
}

#track-info {
	width: auto;
	margin: auto;

	#track-info-inner {
		padding: 10px;
		background: #fafafa;
		border: 1px solid #ddd;
		min-width: 360px;
	}

	h4 {
		margin-bottom: 20px;
		text-align: center;
	}
}

#track-timeline {
	padding-right: 22px;

	ul {
		padding: 0;
	}

	.shipment-icon {
		padding: 6px;
		font-size: 18px;
		color: rgba(#990000, 0.8);
	}
}


/*
** DRIVERS PUBLIC
 */

#driver-app-wrapper {
	padding: 2rem 0;

	a {
		color: #900;
	}

	#logo {
		display: block;
		margin: 0 auto 1rem;
		width: 150px;
	}

	.agreement {
		li {
			margin-bottom: 10px;
		}
	}
}

/*
** PUBLIC PAGE WRAPPER
 */

#public-app-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	padding: 2rem 0;

	a {
		color: #900;
	}

	#logo {
		display: block;
		margin: 0 auto 1rem;
		width: 150px;
	}

	.agreement {
		li {
			margin-bottom: 10px;
		}
	}
}